import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-ion-popover',
  templateUrl: './ion-popover.component.html',
  styleUrls: ['./ion-popover.component.scss'],
})
export class IonPopoverComponent implements OnInit {

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  async onSelectedPopover(options: string) {
    await this.popoverController.dismiss(options);

  }

}
