import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP } from "@ionic-native/http/ngx";
import { HttpClientModule } from "@angular/common/http";
import { AppPreferences } from "@ionic-native/app-preferences/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { File } from "@ionic-native/file/ngx";
import { WorkorderdetailsPopoverComponent } from "./components/workorderdetails-popover/workorderdetails-popover.component";
import { Network } from "@ionic-native/network/ngx";
import { AuthGuardService } from "./service/auth-guard.service";
import { AuthenticationService } from "./service/authentication.service";
import { CloseOrderModalComponent } from "./components/close-order-modal/close-order-modal.component";
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { FormsModule } from "@angular/forms";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { SimplePdfViewerModule } from "simple-pdf-viewer";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { DocViewComponent } from "./components/doc-view/doc-view.component";
import { SafeUrlPipe } from "./service/safe-url.pipe";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { IonPopoverComponent } from "./components/ion-popover/ion-popover.component";

@NgModule({
  declarations: [
    SafeUrlPipe,
    AppComponent,
    WorkorderdetailsPopoverComponent,
    CloseOrderModalComponent,
    PdfViewerComponent,
    DocViewComponent,
    IonPopoverComponent,
  ],
  entryComponents: [
    WorkorderdetailsPopoverComponent,
    CloseOrderModalComponent,
    PdfViewerComponent,
    DocViewComponent,
    IonPopoverComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({
      scrollAssist: false,
    }),
    AppRoutingModule,
    SimplePdfViewerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    AppPreferences,
    Camera,
    File,
    Network,
    AuthGuardService,
    AuthenticationService,
    PhotoViewer,
    Geolocation,
    Keyboard,
    BarcodeScanner,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
